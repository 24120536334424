@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    @apply border border-black rounded-xl px-5 py-1
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Playfair Display',
    sans-serif;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@layer components {
  .record{
      @apply w-1/6
  }
  .headItem{
      @apply w-2/6 px-10 md:w-1/6 border-2 border-black text-white
  }
  .credentialHeadItem{
      @apply w-2/6 px-10 md:w-1/6  text-white
  }
  .recordItem{
      @apply w-3/12 md:w-1/4 text-center border-r-2 border-stone-500
  }
  .gridLayout{
      @apply grid m-5
  }
  .buttonsPrimary{
      @apply hover:bg-primaryDark-300 bg-primaryDark-400 rounded-lg text-center text-primaryDark-200 drop-shadow-lg
  }
  .formField{
      @apply grid w-full
  }
  .questions{
      @apply text-3xl
  }
  .formValuePrimary{
      @apply text-3xl bg-primaryDark-400 p-2 px-5 rounded-xl w-auto 
  }
  .formInput{
      @apply h-10 hover:cursor-text border-primaryDark-200 border-[1px] text-gray-500 rounded-md
  }
}
@media (max-width: 600px) {
  .grid-cols-2 {
    grid-template-columns: 1fr; /* Change to a single column */
  }

  .ml-10 {
    margin-left: auto;
    margin-right: auto; /* Center the image horizontally */
  }
  .text-left{
      text-align: center;
  }
  .tableCol{
      display: none
  }
  .logo{
      display: none
  }
  
}

.fontLink{
  font-family: "Playfair Display";
}

.dashed-underline {
  text-decoration: underline;
  text-decoration-style: dashed;
}


@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway&display=swap");

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
